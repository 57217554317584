var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"divcol margin_global gap2 overflow isolate",attrs:{"id":"library"}},[_c('section',{staticClass:"container-header divcol",staticStyle:{"gap":"2em"}},[_c('img',{staticClass:"pointer back",staticStyle:{"--w":"100px"},attrs:{"src":require("@/assets/icons/back.svg"),"alt":"back"},on:{"click":function($event){return _vm.$router.push('/home')}}}),_vm._m(0)]),_c('aside',{staticClass:"container-actions space gap2"},[_c('div',{staticClass:"wrap gap1 acenter font2",staticStyle:{"height":"2.75em"}},[_c('v-select',{staticStyle:{"max-width":"20ch"},attrs:{"label":"ORDER BY","items":_vm.dataActions.data,"hide-details":"","solo":""},on:{"change":function($event){return _vm.selectRecent()}},model:{value:(_vm.recent),callback:function ($$v) {_vm.recent=$$v},expression:"recent"}})],1),_c('div',{staticClass:"acenter gap1"},[_c('v-text-field',{staticClass:"eliminarmobile",staticStyle:{"--max-w":"14.6875em","--p":"0 1.5em"},attrs:{"placeholder":"Search","id":"search","hide-details":"","solo":""},on:{"input":function($event){return _vm.searchLibrary()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/icons/lupa.svg"),"alt":"search"}})]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('section',{staticClass:"container-content grid",staticStyle:{"--gtc":"repeat(auto-fit, minmax(min(100%, 14.0625em), 1fr))","gap":"clamp(4em, 5vw, 5em)"}},_vm._l((_vm.dataCollection),function(item,i){return _c('v-card',{key:i,staticClass:"divcol gap1",attrs:{"color":"transparent"}},[_c('div',{staticClass:"relative"},[_c('img',{staticStyle:{"--w":"4.279375em"},attrs:{"src":require(`@/assets/icons/${item.play ? 'pause-white' : 'play-white'}.svg`),"alt":"like button","id":"like"},on:{"click":function($event){item.play
              ? (item.play = !item.play)
              : _vm.dataCollection.forEach((e) => {
                  e.play = false;
                  item.play = true;
                });
            _vm.playTrack(item);}}}),_c('img',{staticStyle:{"--f":"drop-shadow(5px 4px 4px rgba(0, 0, 0, 0.25))","--w":"100%"},attrs:{"src":item.img,"alt":"track image"}})]),_c('v-row',[_c('v-btn',{attrs:{"size":"x-large","icon":""},on:{"click":function($event){return _vm.downloadMusic(item)}}},[_c('v-icon',{attrs:{"size":"x-large"}},[_vm._v("mdi-download-box")])],1),_c('div',{staticClass:"divcol"},[_c('h6',{staticClass:"bold p"},[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(_vm._s(item.by))])])],1)],1)}),1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"divcol"},[_c('span',{staticClass:"font2",staticStyle:{"font-size":"16px"}},[_vm._v("LIBRARY")]),_c('h1',{staticClass:"p"},[_vm._v("YOUR COLLECTION")])])
}]

export { render, staticRenderFns }